.home {
  display: grid;
  width: 80%;
  height: 80%;
  grid-template-rows: 70% 30%;
  align-items: center;
}

.home .top {
  display: flex;
  justify-content: center;
}

.home .bottom {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: var(--title);
  line-height: var(--line-height-title);
  color: var(--text);
}

.home-img {
  width: 100%;
  max-width: 1000px;
  min-width: 300px;
  height: auto;
}

@media (max-width: 768px) {
  .home {
    width: 95%;
    height: 90%;
  }
}
