.swiper {
  height: 100svh;
  background: var(--background);
}

.swiper-slide {
  display: grid;
  place-items: center;
}

.swiper-pagination {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: clamp(5px, 1.5vw, 25px);
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
}

.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet {
  display: block;
  margin: clamp(5px, 0.5vw, 25px) 0;
}

.swiper-pagination .swiper-pagination-bullet {
  width: clamp(10px, 1vw, 20px);
  height: clamp(10px, 1vw, 20px);
  border-radius: clamp(25px, 1.5vw, 50px);
  background: var(--text);
}

.swiper-pagination .swiper-pagination-bullet-active {
  width: clamp(15px, 1.5vw, 30px);
  height: clamp(15px, 1.5vw, 30px);
  background: var(--primary);
}

.title {
  font-size: var(--title);
}

.highlight {
  color: var(--primary);
}

.indent-text {
  padding-left: 2.5vw;
}
