.other {
  display: grid;
  width: 80%;
  height: 80%;
  grid-template-columns: 50% 50%;
  align-items: center;
}

.other .left {
  display: flex;
  font-size: var(--content);
  color: var(--text);
  justify-content: center;
  right: 10%;
  transform: translateX(10%);
}

.other .right {
  display: flex;
  font-size: var(--content);
  color: var(--text);
  text-align: right;
  justify-content: center;
}

@media (max-width: 768px) {
  .other {
    width: 95%;
    height: 90%;
    grid-template-columns: 60% 30%;
  }

  .other .left {
    justify-content: left;
    transform: translateX(5%);
  }
  .other .right {
    justify-content: right;
  }
}
