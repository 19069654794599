.about {
  display: grid;
  width: 80%;
  height: 80%;
  grid-template-columns: 50% 50%;
  align-items: center;
}

.about .left {
  display: flex;
  font-size: var(--description);
  line-height: var(--line-height-description);
  color: var(--text);
}

.about-img {
  width: 80%;
  max-width: 500px;
  min-width: 300px;
}

.about .right {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .about {
    width: 85%;
    height: 90%;
    grid-template-columns: 1fr;
  }
}
