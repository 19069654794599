.charachters {
  display: grid;
  width: 60%;
  height: 80%;
  grid-template-rows: 90% 10%;
  align-items: center;
}

.charachters .layout {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--padding);
  width: 100%;
  height: 80%;
  background-color: var(--secondary);
  outline: var(--padding) solid var(--secondary);
  border-radius: var(--border);
}

.box {
  perspective: 500px;
  background-color: var(--primary);
  border-radius: var(--border);
}

.card.is-flipped {
  transform: rotateY(180deg);
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: transform 1000ms;
  transform-style: preserve-3d;
}

.front,
.back {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
  color: var(--text);
  font-size: var(--description);
}

.card .back {
  transform: rotateY(180deg);
}

.card .front {
  transform: rotateY(0deg);
}

.front img,
.back img {
  backface-visibility: hidden;
  width: 65%;
  height: auto;
}

.reference {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reference .bao {
  font-family: "Comic Sans", sans-serif;
  color: var(--primary);
  font-size: var(--description);
}

.reference .bao::before {
  content: "Art by ";
  color: var(--text);
}

.reference .bao::after {
  content: "🤠";
}

.reference .dish {
  font-family: "Raleway Black", sans-serif;
  color: var(--primary);
  font-size: var(--description);
}

.reference .dish::before {
  content: "Music by ";
  color: var(--text);
}

.reference .dish::after {
  content: "🍴";
}

.reference .blob {
  font-family: "Noto Serif", sans-serif;
  color: var(--primary);
  font-size: var(--description);
}

.reference .blob::before {
  content: "Website by ";
  color: var(--text);
}

.reference .blob::after {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  background-image: url('../../../public/assets/Emojis001.png');
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
}


@media (max-width: 1024px) {
  .charachters {
    transform: translateY(2%);
    width: 75%;
  }
}

@media (max-width: 768px) {
  .charachters {
    width: 65%;
    height: 70%;
  }

  .charachters .layout {
    grid-template-columns: repeat(3, 100%);
    grid-auto-flow: column;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }

  .card {
    scroll-snap-align: start;
  }
}

@media (max-width: 480px) {
  .charachters {
    width: 65%;
    height: 90%;
  }

  .front img,
  .back img {
    width: 75%;
  }
}
