@font-face {
  font-family: "Phantom Sans";
  src: url("https://assets.hackclub.com/fonts/Phantom_Sans_0.7/Regular.woff") format("woff"), url("https://assets.hackclub.com/fonts/Phantom_Sans_0.7/Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Phantom Sans";
  src: url("https://assets.hackclub.com/fonts/Phantom_Sans_0.7/Bold.woff") format("woff"), url("https://assets.hackclub.com/fonts/Phantom_Sans_0.7/Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Comic Sans";
  src: url("../public/assets/Comic Sans MS.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Raleway Black";
  src: url("../public/assets/Raleway-Black.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Noto Serif";
  src: url("../public/assets/NotoSerifJP-Regular.ttf") format("truetype");
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Phantom Sans", sans-serif;

  --title: clamp(2rem, 2vw + 1.5rem, 4.5rem);
  --content: clamp(1rem, 1.5vw + 0.6rem, 3rem);
  --description: clamp(1rem, 1vw + 0.5rem, 2rem);
  --box: clamp(0.75rem, 0.75vw + 0.35rem, 1.5rem);

  --border: clamp(15px, 2vw, 50px);
  --padding: clamp(5px, 0.5vw, 10px);

  --line-height-title: 1;
  --line-height-content: 1.125;
  --line-height-description: 1.5;
  
  transition: all 0.5s ease;
  user-select: none;
}

:root {
  --background: #17171d;
  --text: #ffffff;
  --primary: #ec3750;
  --secondary: #8492a6;
  --accent: #5bc0de;
}

[theme="light"] {
  --background: #ffffff;
  --text: #1f2d3d;
  --primary: #ec3750;
  --secondary: #3c4858;
  --accent: #338eda;
}
