.faq .layout::-webkit-scrollbar {
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--secondary);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
  border: 3px solid var(--secondary);
}

::-webkit-scrollbar-button {
  display: none; /* Hide the scrollbar buttons */
}

/* Firefox scrollbar styles */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) var(--secondary); /* Thumb and track colors */
}

/* Edge and IE scrollbar styles */
* {
  -ms-overflow-style: scrollbar;
}

.faq {
  display: grid;
  width: 80%;
  height: 80%;
  transform: translateX(-2%);
}

.faq .layout {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
  gap: var(--padding);
  height: 100%;
  background-color: var(--secondary);
  outline: var(--padding) solid var(--secondary);
  border-radius: var(--border);
  overflow: hidden;
}

.note {
  display: flex;
  padding: var(--padding);
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 0;
  background-color: var(--background);
  border-radius: var(--border);
}

.text {
  font-size: var(--box);
  line-height: var(--line-height-description);
  color: var(--text);
}

.text .heading {
  font-size: var(--content);
  line-height: var(--line-height-content);
}

@media (max-width: 1024px) {
  .faq {
    transform: translateY(2%);
    width: 85%;
    height: 60%;
  }
}

@media (max-width: 768px) {
  .faq {
    transform: translateY(2%) translateX(-1%);
  }

  .faq .layout {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 100%);
    grid-auto-flow: column;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }

  .note {
    scroll-snap-align: start;
  }

  .text {
    font-size: var(--content);
    line-height: var(--line-height-content);
  }

  .text .heading {
    font-size: var(--title);
    line-height: var(--line-height-title);
  }
}
